import { Component, OnInit } from '@angular/core';
declare var jQuery;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  aux: boolean = false;
  constructor() { }

  ngOnInit() {
    (function ($) {
      "use strict";
      /* Navbar Scripts */
      // jQuery to collapse the navbar on scroll
      $(window).on('scroll load', function () {
        if ($(".navbar").offset().top > 60) {
          $(".fixed-top").addClass("top-nav-collapse");
        } else {
          $(".fixed-top").removeClass("top-nav-collapse");
        }
      });

      // jQuery for page scrolling feature - requires jQuery Easing plugin
      $(function () {
        $(document).on('click', 'a.page-scroll', function (event) {
          var $anchor = $(this);
          $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
          }, 600, 'easeInOutExpo');
          event.preventDefault();
        });
      });

      // closes the responsive menu on menu item click
      $(".navbar-nav li a").on("click", function (event) {
        if (!$(this).parent().hasClass('dropdown'))
          $(".navbar-collapse").collapse('hide');
      });

      /* Removes Long Focus On Buttons */
      $(".button, a, button").mouseup(function () {
        $(this).blur();
      });

    })(jQuery);
  }
  showCollapse() {
    var nav = document.getElementById('navbarCollapse')
    if (this.aux == false) {
      console.log('show')
      nav.classList.add('show');
    } else {
      console.log('hide')
      nav.classList.remove('show');
    }
    this.aux = !this.aux
  }
}
