import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  inventory: any = [
    {
      "id": 1,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "ECOSYS m2040dn",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $7,500.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM1-1.jpeg",
      "images": ["IM1.jpeg","IM1-2",]
    },
    {
      "id": 2,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "Taskalfa 8003i",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $43,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM2.jpeg",
      "images": ["IM2.jpeg",]
    },
    {
      "id": 3,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "Taskalfa 8002i",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $62,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM3.jpeg",
      "images": ["IM3-1.jpeg",]
    },
    {
      "id": 4,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "Taskalfa 4002i",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $27,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM4.jpeg",
      "images": ["IM4.jpeg",]
    },
    {
      "id": 5,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "FS 4100",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $3,500.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM5.jpeg",
      "images": ["IM5-1.jpeg",]
    },
    {
      "id": 6,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "ECOSYS P2040dn",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $3,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM6.jpeg",
      "images": ["IM6.jpeg","IM6-1",]
    },
    {
      "id": 7,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "KYOCERA",
      "model": "Taskalfa 4002i",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $22,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM7.JPG",
      "images": ["IM7.JPG",]
    },
    {
      "id": 8,
      "identified": "EQUIPO NO DISPONIBLE",
      "amount": "1",
      "brand": "Konica Minolta",
      "model": "Bizhub C458",
      "bncounter": "100K",
      "colorcounter": "85K",
      "totalcounter": "185K",
      "pagesperminute": "25PPM",
      "quality": "LASER Monocromo",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $45,000.00 ",
      "printresolution": "1200x1200dpi",
      "maximumpapersizes": "12X18 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "00GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "99%",
      "developmentUnitC": "99%",
      "imagingUnitM": "99%",
      "developmentUnitM": "99%",
      "imagingUnitY": "99%",
      "developmentUnitY": "99%",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "IM8.JPG",
      "images": ["IM8.JPG",]
    },
    {
      "id": 9,
      "identified": "0000",
      "amount": "1",
      "brand": "Konica Minolta",
      "model": "Bizhub 454",
      "bncounter": "300K",
      "colorcounter": "240K",
      "totalcounter": "540K",
      "pagesperminute": "80PPM",
      "quality": "Monocromo LASER",
      "status": "REACONDICIONADO",
      "warranty": "3 MESES O 30MIL PAGINAS",
      "priceIVA": " $25,000.00 ",
      "printresolution": "600dpi",
      "maximumpapersizes": "11X 17 PULGADAS",
      "minimumpapersizes": "TAMAÑO SOBRE",
      "maximumpaperweight": "220GR",
      "consumablelife": "99%",
      "imagingunitK": "99%",
      "developmentUnitK": "99%",
      "imagingUnitC": "N/A",
      "developmentUnitC": "N/A",
      "imagingUnitM": "N/A",
      "developmentUnitM": "N/A",
      "imagingUnitY": "N/A",
      "developmentUnitY": "N/A",
      "transferBelt": "99%",
      "fixingUnit": "99%",
      "foodGums": "99%",
      "homeImage": "01konicaInolta.jpg",
      "images": ["01konicaInolta.jpg", "02konicaInolta.jpg", "03konicaInolta.jpg", "001product.jpg", "002product.jpg", "003product.jpg", "004product.jpg", "005product.jpg", "006product.jpg", "007product.jpg"]
    }
  ]
  constructor() { }
  getProducts() {
    return this.inventory;
  }
  getProductsHome() {
    return this.inventory
  }
  getProductId(id) {
    var element: any;
    for (let i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].id === id) {
        element = this.inventory[i]
      }
    }
    return element;
  }
}
