import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../provider/products.service';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {
  products: any = []
  path: string = "../../assets/products/";
  constructor(private api: ProductsService, public router: Router) { }

  ngOnInit() {
    this.products = this.api.getProducts();
  }
  goDetailsPage(item) {
    this.router.navigate(["/product/", item.id]);
  }
}
