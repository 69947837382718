import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../provider/products.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  data: any;
  path: string = "../../assets/products/";
  constructor(public products: ProductsService, private router: Router) { }
  ngOnInit() {
    this.data = this.products.getProducts();
  }
  showProduct(item) {
    this.router.navigate(["/product/", item.id]);
  }
}
