import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../provider/products.service';
@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.css']
})
export class DetailsProductComponent implements OnInit {
  data: any;
  path: string = "../../assets/products/";
  products: any;
  slideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 2,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000
  };
  constructor(public product: ProductsService, public activeRoute: ActivatedRoute) {
  }
  ngOnInit() {
    const id = this.activeRoute.snapshot.params.id
    this.data = this.product.getProductId(parseInt(id));
    this.products = this.data.images
  }
  removeSlide() {
    this.products.length = this.products.length - 1;
  }
  slickInit(e) {
    console.log('slick initialized');
  }
  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    console.log('afterChange');
  }
  beforeChange(e) {
    console.log('beforeChange');
  }
}
