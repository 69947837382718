import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './products/products.component';
import { OurSolutionsComponent } from './our-solutions/our-solutions.component';
import { KnowUsComponent } from './know-us/know-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SliderComponent } from './slider/slider.component';
import { DetailsProductComponent } from './details-product/details-product.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AvisoComponent } from './components/aviso/aviso.component';
import { ValoresComponent } from './components/valores/valores.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    NotFoundComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    CopyrightComponent,
    FooterComponent,
    ProductsComponent,
    OurSolutionsComponent,
    KnowUsComponent,
    ContactUsComponent,
    SliderComponent,
    DetailsProductComponent,
    CatalogueComponent,
    AvisoComponent,
    ValoresComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
