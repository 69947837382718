import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $;
declare var jQuery;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public router: Router) { }
  ngOnInit() {
  }
  showAll() {
    this.router.navigateByUrl("/products");
  }
}
