import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DetailsProductComponent } from './details-product/details-product.component';
import { HomeComponent } from './home/home.component';
import { KnowUsComponent } from './know-us/know-us.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OurSolutionsComponent } from './our-solutions/our-solutions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProductsComponent } from './products/products.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
//Ricardo
import { AvisoComponent } from './components/aviso/aviso.component';
import { ValoresComponent } from './components/valores/valores.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'politicas-de-devolucion', component: PrivacyPolicyComponent },
  { path: 'terminos-y-condiciones', component: TermsConditionsComponent },
  { path: 'nuestros-productos', component: ProductsComponent },
  { path: 'nuestras-soluciones', component: OurSolutionsComponent },
  { path: 'conocenos', component: KnowUsComponent },
  { path: 'contactanos', component: ContactUsComponent },
  { path: 'products', component: CatalogueComponent },
  { path: 'product/:id', component: DetailsProductComponent },

 //Ricardo
  { path: 'aviso', component: AvisoComponent },
  { path: 'valores', component: ValoresComponent},

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
